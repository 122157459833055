import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Tooltip } from "antd";
import { BulbOutlined, BulbFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Svgs } from "../../../components/Svgs";
import { BrowserQRCodeReader } from "@zxing/library";

export const ScanQR = ({ onChange }) => {
  const [scanResult, setScanResult] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isFlashlightOn, setIsFlashlightOn] = useState(false);
  const { t } = useTranslation();
  const videoTrackRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const codeReader = useRef(new BrowserQRCodeReader());

  const handleScan = (data) => {
    if (data && data !== scanResult) {
      // Check if scan result is different
      setScanResult(data);
      onChange && onChange(data);
      setIsCameraVisible(false);
      toggleFlashlight(false);
      codeReader.current.reset(); // Stop the decoding process after the scan
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const toggleCamera = async (status) => {
    setIsCameraVisible(status);
    if (status) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        streamRef.current = stream;
        videoRef.current.srcObject = stream;

        // Wait for the video to load metadata before playing
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((error) => {
            console.error("Video play error:", error);
          });
        };

        // Start the QR reader
        codeReader.current.reset(); // Ensure previous session is cleared
        codeReader.current.decodeFromVideoDevice(
          null,
          videoRef.current,
          (result, error) => {
            if (result) handleScan(result);
            if (error && error !== "NotFoundException") handleError(error); // Handle specific errors
          }
        );
      } catch (error) {
        console.error("Camera access error:", error);
      }
    } else {
      // Stop the video stream and release the camera
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
      codeReader.current.reset(); // Reset the reader when camera is closed
    }
  };

  const toggleFlashlight = async (status) => {
    if (streamRef.current) {
      // Get the video track from the stream
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        const capabilities = videoTrack.getCapabilities();
        if (capabilities.torch) {
          try {
            await videoTrack.applyConstraints({
              advanced: [{ torch: status }],
            });
            setIsFlashlightOn(status);
          } catch (error) {
            console.error("Error toggling flashlight:", error);
          }
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup when camera is hidden
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
      codeReader.current.reset(); // Ensure we clean up any running QR reader
    };
  }, []);

  const scannerStyle = {
    width: "100%",
    height: "197px",
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  };

  const frameStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "70%",
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const cornerStyle = {
    position: "absolute",
    width: "40px",
    height: "40px",
  };

  const cornerPositions = {
    topLeft: {
      top: "-2px",
      left: "-2px",
      borderTop: "2px solid #FFF",
      borderLeft: "2px solid #FFF",
    },
    topRight: {
      top: "-2px",
      right: "-2px",
      borderTop: "2px solid #FFF",
      borderRight: "2px solid #FFF",
    },
    bottomLeft: {
      bottom: "-2px",
      left: "-2px",
      borderBottom: "2px solid #FFF",
      borderLeft: "2px solid #FFF",
    },
    bottomRight: {
      bottom: "-2px",
      right: "-2px",
      borderBottom: "2px solid #FFF",
      borderRight: "2px solid #FFF",
    },
  };

  return (
    <Card className="custom-card" bodyStyle={{ padding: "0px" }}>
      <div style={{ fontWeight: "500", fontSize: "18px" }}>
        {t("start_charging")}
      </div>
      <div
        style={{
          fontWeight: "400",
          fontSize: "14px",
          marginBottom: "24px",
          color: "#52525B",
        }}
      >
        {t("guide_scan")}
      </div>
      {!isCameraVisible && (
        <Button
          type="primary"
          className="start-button"
          onClick={() => toggleCamera(true)}
        >
          {t("scan_QR")}
        </Button>
      )}
      {isCameraVisible && (
        <div style={scannerStyle}>
          <video
            ref={videoRef}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            autoPlay
            muted
          />
          <div style={frameStyle}>
            <div style={{ ...cornerStyle, ...cornerPositions.topLeft }}></div>
            <div style={{ ...cornerStyle, ...cornerPositions.topRight }}></div>
            <div
              style={{ ...cornerStyle, ...cornerPositions.bottomLeft }}
            ></div>
            <div
              style={{ ...cornerStyle, ...cornerPositions.bottomRight }}
            ></div>
          </div>

          <Tooltip
            title={
              isFlashlightOn
                ? t("turn_off_flashlight")
                : t("turn_on_flashlight")
            }
          >
            <Button
              type="primary"
              shape="circle"
              icon={
                isFlashlightOn ? (
                  <Svgs.IconTorchOnSvg />
                ) : (
                  <Svgs.IconTorchOffSvg />
                )
              }
              onClick={() => toggleFlashlight(!isFlashlightOn)}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                zIndex: 1,
                width: "40px",
                height: "40px",
                backgroundColor: isFlashlightOn ? "#FFFFFF" : "#FFFFFF33",
              }}
            />
          </Tooltip>
        </div>
      )}
      {isCameraVisible && (
        <Button
          type="primary"
          style={{
            backgroundColor: "#FFF",
            borderWidth: "1px",
            borderColor: "#E4E4E7",
            width: "100%",
            marginTop: "24px",
            color: "#18181B",
            fontWeight: "600",
            height: "44px",
          }}
          onClick={() => toggleCamera(false)}
        >
          {t("cancel")}
        </Button>
      )}
      <div
        style={{
          fontWeight: "400",
          fontSize: "14px",
          marginTop: "16px",
          color: "#52525B",
        }}
      >
        Powered by{" "}
        <span
          style={{ color: "#2D9764", fontWeight: "500", cursor: "pointer" }}
          onClick={() => {
            window.open("https://evercharge.vn/");
          }}
        >
          Evercharge
        </span>
      </div>
    </Card>
  );
};
